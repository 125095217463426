<template>
<div class="center examplex">
    <vs-navbar class="nav" square shadow center-collapsed not-line v-model="active">
        <template #left>
            <router-link :to="{ name: 'Home'}"> 
                <img class="logo" src="@/assets/images/logo.png" alt="">
            </router-link>
        </template>

        

        <template #right>
            <vs-navbar-item v-if="!showMenu" :active="active == 'home'" id="home" :to="{ name: 'Home' }">
                HOME
            </vs-navbar-item>
            <vs-navbar-item v-if="!showMenu" :active="active == 'overons'" id="overons" @click="routing('over-ons')">
                OVER ONS
            </vs-navbar-item>

            <vs-navbar-group v-if="!showMenu">
                DIENSTEN

                <template #items>
                    <vs-navbar-item :active="active == 'advies'" id="advies" :to="{ name: 'advies' }">
                        Advies
                    </vs-navbar-item>
                    <vs-navbar-item :active="active == 'inspecties'" id="inspecties" :to="{ name: 'inspecties' }">
                        Inspecties
                    </vs-navbar-item>
                    <vs-navbar-item :active="active == 'electrotechniek'" id="electrotechniek" :to="{ name: 'electrotechniek' }">
                        Electrotechniek
                    </vs-navbar-item>
                </template>
            </vs-navbar-group>
                
            <vs-navbar-item v-if="!showMenu" :active="active == 'contact'" id="contact" :to="{ name: 'Contact' }">
                CONTACT
            </vs-navbar-item>  

            <vs-navbar-group v-if="showMenu" :active="active == 'v'" id="cvxv">
                <box-icon name='dots-vertical-rounded'></box-icon>
                <template #items>
                    <vs-navbar-item v-if="showMenu" :active="active == 'home'" id="home" :to="{ name: 'Home' }">
                        HOME
                    </vs-navbar-item>
                    <vs-navbar-item :active="active == 'overons'" id="overons" @click="routing('over-ons')">
                        OVER ONS
                    </vs-navbar-item>
                    <vs-navbar-item :active="active == 'diensten'" @click="routing('diensten')">
                        DIENSTEN
                    </vs-navbar-item>
                    <vs-navbar-item :active="active == 'contact'" id="contact" :to="{ name: 'Contact' }">
                        CONTACT
                    </vs-navbar-item>  
                </template>
            </vs-navbar-group>
        </template>

    </vs-navbar>
</div>
</template>

<script>
export default {
    computed: {
        active: {
            // getter
            get: function () {
                return this.$store.state.active.toLowerCase();
            },
            // setter
            set: function (newValue) {
                this.$store.dispatch("setActive", newValue);
            },
        },

        showMenu() {
            return document.body.getBoundingClientRect().width < 530
        }

    },
    methods: {
        routing(element) {
            if(this.$route.name != 'Home') this.$router.push('/')
            setTimeout(() => {
                this.$scrollTo('#' + element)
            }, 500);
        }
    },
}
</script>

<style lang="sass" scoped>
    .logo
        margin-top: 10px
        width: 60px
        height: 60px

    /deep/ .vs-navbar__group__items
        left: -110px

        @media only screen and (min-width: 531px) // > tablet   
            left: -30px

    /deep/ .vs-navbar__group:hover .vs-navbar__group__items
        transform: translate(0%, 120%)

    /deep/ .vs-navbar__group__items .vs-navbar__item
        text-align: unset

        @media only screen and (min-width: 531px) // > tablet   
            text-align: left

    .nav
        border-top: 2.5px solid var(--blue)
        background-color: #F7F7F7

    .nav-link
        color: white
</style>
