<template>
<div>
    <hooper :settings="hooperSettings" class="carousel">
        <slide v-for="(slide, index) in this.slides" :key="index" :index="index">
            <img class="landscape" :src="slide.source" alt="">
        </slide>

        <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
</div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
    props: ['slides'],

    data() {
        return {
            hooperSettings: {
                progress: true,
                centerMode: true,
                infiniteScroll: true,
                autoPlay: true,
                playSpeed: 8000,
                itemsToShow: 1,
                touchDrag: true,
                mouseDrag: true,
                shortDrag: true,
                wheelControl: false,
                keysControl: true,
                hoverPause: true,
                vertical: false,
            },
        }
    },
    components: {
        Hooper,
        Slide,
        HooperNavigation,
    },

    }
</script>

<style lang="sass" scoped>
.carousel
    // height: inherit // smaller carousel on small devices for design purposes
    position: relative
    // //fixes some stupid outlining
    width: 99%
    margin: auto
    outline: 0

    @media only screen and (min-width: 786px) // > tablet
        height: 50vh

.landscape
    display: block 
    width: 100%
    object-fit: cover
    padding: 5px 5px
    height: 100%
    border-radius: 20px

    @media only screen and (min-width: 786px) // > tablet
        height: 100%

/deep/ .icon-arrowLeft
    filter: invert(1)

/deep/ .icon-arrowRight
    filter: invert(1)

</style>