<template>
    <vs-row id="diensten" justify="center">
        <vs-col :w="wModule()" class="module box-shadow">
            <vs-row justify="center">
                <vs-col w="12"><div class="line"></div></vs-col>
                <vs-col w="12" class="space">
                    <div class="background">
                        <h1 class="title">Diensten</h1>
                    </div>
                </vs-col>

                <vs-col w="12">
                    <img class="landscape image" src="@/assets/images/network.jpg" alt="">
                </vs-col>

                <vs-col :w="w0()">
                    <h4>Wij leveren de volgende diensten:</h4>
                    <h4>
                     <router-link class="nav-link" :to="{ name: 'advies' }">Advies</router-link> | <router-link class="nav-link" :to="{ name: 'inspecties' }">Inspecties</router-link> | <router-link class="nav-link" :to="{ name: 'electrotechniek' }">Electrotechniek</router-link> | Data-installaties | 
                        Inbraakbeveiliging | Brandmeldsystemen | Service & onderhoud
                    </h4>
                    <h4>Neem gerust contact met ons op voor vragen over de diensten die we leveren. <br>
                        Zie onderaan de pagina voor onze telefoonnummer en e-mailadres.<br>
                        STORINGSDIENST: Wij zijn 24/7 telefonisch beschikbaar
                    </h4>
                </vs-col>

                <vs-col w="12" class="space">
                   <vs-button class="btn contact-btn" gradient size="xl" @click="$router.push('contact')">
                       CONTACT
                        <box-icon name='paper-plane' color="white"></box-icon>
                   </vs-button>
                </vs-col>
                <vs-col w="12"><div class="line"></div></vs-col>
            </vs-row>
        </vs-col>
    </vs-row>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
    }
</script>

<style lang="sass" scoped>
.landscape
    display: block 
    width: 80%
    object-fit: cover
    margin: auto

.contact-btn
    margin: auto
    border-radius: 10px

.module
    margin-bottom: 30px

.nav-link
    color: #2c3e50
    // margin: 0px 10px 0px 10px
    // text-decoration: underline
    // cursor: pointer
</style>