<template>
    <vs-row justify="center">
      <vs-col :w="wModule()">
        <div class="pic box-shadow">

    <div class="ribbon ribbon-top-left"><span><a style="color: white; text-decoration-color: lightgray; font-weight:900" href="https://zoeken-mijn.s-bb.nl/Home/Details?leerbedrijfId=100696659">{{ content() }}</a></span></div>

          <div class="center-block">
            <div class="headline">
              <img class="logo" src="@/assets/images/logo.png" alt="">
              <h1 class="title">MD Electrotechniek</h1>
            </div>

            <div class="subline">
              <vs-button class="contact-btn" gradient size="xl" @click="$router.push('contact')">
                  CONTACT
                  <box-icon name='paper-plane' color="white"></box-icon>
              </vs-button>
            </div>
          </div>

        </div>
      </vs-col>
    </vs-row>
</template>

<script>
    export default {
        methods: {
          wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
          },
          content() {
            return this.$store.getters.smallDevice ? "SBB erkend leerbedrijf!" : "Wij zijn een erkend leerbedrijf!"
          },
        },
    }
</script>

<style lang="sass" scoped>
.title
  font-weight: 900

.contact-btn
  margin: auto
  border-radius: 10px

.center-block
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.headline
  background-color: #F7F7F7
  padding: 15px 20px
  border-radius: 20px
  
  @media only screen and (max-width: 425px) // < small phone 
    margin-top: 60px
    padding: 5px

.subline
  margin-top: 40px

.logo
  width: 80px
  height: 80px
  display: inline
  vertical-align:middle

.pic
  background-image: url("../assets/images/arduino.jpg")
  background-position: center
  height: 75vh
  width: 100%
  border-radius: 10px 

  @media only screen and (min-width: 768px) // > tablet    
    background-position: initial


/* common */
.ribbon 
  width: 300px
  height: 250px
  overflow: hidden
  position: absolute

  @media only screen and (max-width: 650px) // < tablet    
    width: 180px
    height: 150px

.ribbon::before,
.ribbon::after 
  position: absolute
  z-index: -1
  content: ''
  display: block
  border: 5px solid #cc9900


.ribbon span 
  position: absolute
  display: block
  width: 430px
  padding: 15px 0
  // background-image: linear-gradient(45deg, #30cfd0 0%, #330867 100%)
  background-image: linear-gradient(to top, #e6b980 0%, #eacda3 100%)
  box-shadow: 0 5px 10px rgba(0,0,0,.1)
  color: #fff
  font: 700 18px/1 'Lato', sans-serif
  text-shadow: 0 1px 1px rgba(0,0,0,.2)
  text-transform: capitalize
  text-align: center

  @media only screen and (max-width: 650px) // < tablet    
    width: 330px
    font-size: 15px

/* top left */
.ribbon-top-left 
  top: -10px
  left: -10px

.ribbon-top-left::before,
.ribbon-top-left::after 
  border-top-color: transparent
  border-left-color: transparent

.ribbon-top-left::before 
  top: 0
  right: 0

.ribbon-top-left::after 
  bottom: 0
  left: 0

.ribbon-top-left span 
  right: -45px
  top: 90px
  transform: rotate(-40deg)

  @media only screen and (max-width: 650px) // < tablet    
    right: -58px
    top: 40px
    // transform: rotate(-45deg)


</style>