import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active: 'home',
    images: [
      {source: require("@/assets/images/tools.jpeg")},
      {source: require("@/assets/images/welding.jpeg")},
      {source: require("@/assets/images/switches.jpeg")},
      {source: require("@/assets/images/servers.jpg")},
    ],
  },
  mutations: {
    SET_ACTIVE(state, payload) {
      state.active = payload
    }
  },
  actions: {
    setActive({ commit }, newValue) {
      commit('SET_ACTIVE', newValue)
    },
  },
  getters: {
    extraSmallDevice: () => {
      return document.body.getBoundingClientRect().width < 500
    },
    smallDevice: () => {
      return document.body.getBoundingClientRect().width < 786
    },

    mediumDevice: () => {
      return document.body.getBoundingClientRect().width > 484 && document.body.getBoundingClientRect().width < 786
    },

    largeDevice: () => {
      return document.body.getBoundingClientRect().width > 786 && document.body.getBoundingClientRect().width < 1200
    },
  },
  modules: {
  }
})
