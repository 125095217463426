<template>
    <vs-row id="over-ons" justify="center">
        <vs-col :w="wModule()" class="module box-shadow">
            <vs-row justify="center">
                <vs-col w="12"><div class="line"></div></vs-col>
                <vs-col w="12" class="space">
                    <div class="background">
                        <h1 class="title">Over ons</h1>
                    </div>
                </vs-col>

                <vs-col w="12">
                    <Carousel :slides="$store.state.images" />
                </vs-col>

                <vs-col :w="w0()">
                    <h4>MD electrotechniek is een professionele electro-solution dienstverlener. 
                        Sinds 2008 werken wij samen met particulieren en bedrijven aan allerlei solutions rondom electrotechniek. 
                        Van Data-installaties tot aan brandmeldsystemen, MD electrotechniek staat garant. <br><br>
                        Wij staan ook bij SBB bekend als erkend leerbedrijf (klik <a style="color: black;" href="https://zoeken-mijn.s-bb.nl/Home/Details?leerbedrijfId=100696659">hier</a> voor meer informatie).<br>
                        Neem contact met ons op en kom erachter of MD electrotechniek wat voor u kan betekenen!
                    </h4>
                </vs-col>

                
                <vs-col w="12"><div class="line"></div></vs-col>
            </vs-row>
        </vs-col>
    </vs-row>
</template>

<script>
import Carousel from '@/components/Carousel.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        Carousel,
    },
    methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
