<template>
  <div class="center grid">
    <Landing/>
    <OverOns/>
    <Diensten/>
  </div>
</template>

<script>
import Landing from './Landing.vue'
import Diensten from './Diensten.vue'
import OverOns from './OverOns.vue'

export default {
  components: {
    Landing,
    OverOns,
    Diensten
  }
}
</script>

<style lang="sass" scoped>
.grid
  padding: 10px
</style>