<template>
    <div class="footer">
        <vs-row>

            <vs-col :w="this.fillerWidth"/>

            <vs-col :w="this.gridWidth">

                <vs-row>
                    <vs-col align="left" w="6">
                        <img class="logo left" src="@/assets/images/logo.png" alt="">
                    </vs-col>

                    <vs-col align="left" w="6">
                        <h5 class="left contact">
                            <span class="bold">Contactgegevens:</span><br>
                            Tel: <a style="color:black" class="bold" href="tel:316-51398002">(+)31 6 513 980 02</a><br>
                            E-mail: <a style="color:black" class="bold" href="mailto:info@mdelectro.nl">info@mdelectro.nl</a><br>
                            <br>
                            <span class="bold">Bezoekadres:</span><br>
                            Tijnmuiden 23<br>
                            1046 AK, Amsterdam
                        </h5>
                    </vs-col>
                </vs-row>

            </vs-col>


            <vs-col :w="this.gridWidth">


                <vs-row>
                    <vs-col align="left" :w="this.mapsWidth">
                        <h5 class="left nav-links">
                            <a class="bold nav-link" href="/">HOME</a> <br class="only-desktop">
                            <br class="only-desktop">
                            <a class="bold nav-link" @click="routing('over-ons')">OVER ONS</a> <br class="only-desktop">
                            <br class="only-desktop">
                            <a class="bold nav-link" @click="routing('diensten')">DIENSTEN</a> <br class="only-desktop">
                            <br class="only-desktop">
                            <a class="bold nav-link" @click="$router.push('contact')">CONTACT</a> <br class="only-desktop">
                        </h5>
                    </vs-col>

                    <vs-col align="left" w="6">
                        <!-- <iframe class="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d304.36450051000617!2d4.779426722739099!3d52.39007299006153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5e39d2b76e703%3A0xd04a7fe2a97a28e!2sTijnmuiden%2023%2C%201046%20AL%20Amsterdam!5e0!3m2!1snl!2snl!4v1623958901493!5m2!1snl!2snl" 
                        style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                        <iframe class="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d304.36450051000617!2d4.779426722739099!3d52.39007299006153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5e39d2b76e703%3A0xd04a7fe2a97a28e!2sTijnmuiden%2023%2C%201046%20AL%20Amsterdam!5e0!3m2!1snl!2snl!4v1623965078052!5m2!1snl!2snl" 
                        style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </vs-col>
                </vs-row>

                
            </vs-col>


            <vs-col :w="this.fillerWidth"/>

        </vs-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                screenWidth: 0,
            }
        },
        beforeMount () {
            this.setScreenWidth()
        },
        mounted () {
            window.addEventListener('resize', this.setScreenWidth);
        },
        methods: {
            setScreenWidth() {
                this.screenWidth = window.innerWidth
            },
            routing(element) {
                if(this.$route.name != 'Home') this.$router.push('/')
                setTimeout(() => {
                    this.$scrollTo('#' + element)
                }, 500);
            }
        },
        computed: {
            gridWidth() {
                if (this.screenWidth > 1050) { 
                    return 4 //grid width of 4
                } else if (this.screenWidth >= 768 && this.screenWidth <= 1050) {
                    return 5
                }
                return 12 //grid width of 12
            },
            fillerWidth() {
                if (this.screenWidth > 1050) { 
                    return 2
                }
                return 0
            },
            mapsWidth() {
                if (this.screenWidth >= 768) { 
                    return 5
                }
                return 12
            },
        },
    }
</script>

<style lang="sass" scoped>

    $margin: 30px

    .footer
        // position: fixed
        left: 0
        bottom: 0
        width: 100%
        text-align: center
        background-color: white

    .logo
        height: 120px 
        width: 120px
        margin-top: $margin
        border-radius: 20px

    .logo, .address
        margin-left: $margin

    .maps
        // width: 250px
        width: calc( 100vw - 60px )
        height: 150px
        border-radius: 5px
        margin-left: $margin
        margin-top: $margin
        margin-bottom: 20px
        
        @media only screen and (min-width: 768px) // > tablet    
            width: 280px
            margin-right: $margin

    .nav-links, .contact
        margin-top: $margin

    .nav-links
        margin-left: $margin
        
    .nav-link
        color: black
        margin: 0px 10px 0px 10px
        text-decoration: underline
        cursor: pointer

        @media only screen and (min-width: 786px) // > tablet  
            margin-left: 20px

    .only-desktop
        display: none

        @media only screen and (min-width: 768px) // > tablet  
            display: initial
</style>