import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/diensten/electrotechniek',
    name: 'electrotechniek',
    component: () => import('../views/Diensten-electrotechniek.vue')
  },
  {
    path: '/diensten/advies',
    name: 'advies',
    component: () => import('../views/Diensten-advies.vue')
  },
  {
    path: '/diensten/inspecties',
    name: 'inspecties',
    component: () => import('../views/Diensten-inspecties.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
