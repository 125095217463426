<template>
  <div id="app" vs-theme="light">
    <NavBar/>
    <router-view class="view"/>
    <Footer class="foot"/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    NavBar,
    Footer,
  },
}
</script>

<style lang="sass">
@font-face 
  font-family: 'Lato'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
  
[vs-theme=light]
  --blue: #195BFF

#app 
  // font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

*
  font-family: Lato
  font-weight: 300
  letter-spacing: 0.2px

body
  background-color: #F7F7F7
  overflow-x: hidden

html
  overflow-x: hidden

.module
  background: white
  padding: 20px
  border-radius: 10px
  margin-top: 30px

  @media only screen and (min-width: 786px) // > tablet
    padding: 40px

.space
  margin-bottom: 30px

.image
  border-radius: 10px 

.box-shadow
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px

.line
  background-color: rgb(25, 91, 255)
  width: 40px
  height: 6px
  border-radius: 90px
  margin: auto

.background
  background-image: linear-gradient(45deg, #30cfd0 0%, #330867 100%)
  color: white
  border-radius: 25px
  margin: auto
  width: fit-content

  @media only screen and (min-width: 786px) // > tablet
    margin-left: -70px
    float: left

.title
  margin: 20px 30px
  padding: 13px

  @media only screen and (min-width: 786px) // > tablet
    padding: 0px

</style>

<style lang="sass" scoped>
.view 
  margin-top: 86px

.foot 
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
  margin-left: -8px
  margin-right: -8px
  width: 100vw
</style>
